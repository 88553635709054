import moment from "moment";
import React, { memo, useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, ITableStructureItem, Icon, InputWraper, NumberUtils, Table, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withUserWraper } from "../wraper";
import * as Yup from "yup";
import { InputText } from "../../../components";

export const UserPromotion = withUserWraper(
  memo(() => {
    const [params, setParams] = useState([] as any[]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [type, setType] = useState<"begin" | "pass">("begin");
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

    const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

    const { handleSubmit, getInputProps } = useForm({
      structure: [
        {
          name: "email",
          validate: Yup.string().required(translate("must-be-provided")),
        },
      ],

      onSubmit: async (values) => {
        return AdminService.beginOrPassPromotion(values?.email, type)
          .then(() => {
            CreateAlert({
              message: `${type === "begin" ? "Begin" : "Pass"} success.`,
              type: "success",
            });
            setForceUpdateTable((state) => !state);
          })
          .catch((error) => {
            CreateAlert({
              message: translate(error.message),
              type: "danger",
            });
          });
      },
    });

    const structure: ITableStructureItem[] = [
      {
        name: "EMAIL",
        key: "email",
        render: (item) => {
          return <span className="email">{item.email}</span>;
        },
      },
      {
        name: "BALANCE",
        key: "amount",
        render: (item: any) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
      },
      {
        name: "TIME",
        key: "created",
        render: (item) => moment(item?.created).format("DD/MM/y HH:mm:ss"),
      },
      {
        name: "END",
        key: "modified",
        render: (item) => moment(item?.modified).format("DD/MM/y HH:mm:ss"),
      },
      {
        name: "STATUS",
        key: "status",
        render: (item) => <span className={`status status--${item?.status?.toLowerCase()}`}>{translate(item?.status)}</span>,
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListPromotion({
            ...params,
            page: 1,
            numberOfItemsPerPage: 10000,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `User Promotion ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error) {
          // @ts-ignore
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    return (
      <div className="UserPromotion">
        <div className="mb20 d-flex align-items-center justify-content-between">
          <Button label="Export to Excel" buttonType="success" onClick={handleExportExcel} />

          <div className="total-amount">
            <div className="total-amount__icon">
              <Icon.TotalVolumeIcon />
            </div>
            <div className="total-amount__content">
              ${new Intl.NumberFormat("en").format(totalAmount ?? 0)}
              <span className="sub-text">&nbsp;TOTAL</span>
            </div>
          </div>
        </div>

        <div className="config">
          <div className="d-flex align-items-center">
            <InputWraper label={translate("email-address")} inputProps={getInputProps("email")} component={InputText} />
            <Button
              className="ml20 mr10 mt10"
              label="BEGIN"
              onClick={() => {
                handleSubmit();
                setType("begin");
              }}
              isMiddle
              buttonType="info"
            />

            <Button
              className="mt10"
              label="PASS"
              onClick={() => {
                handleSubmit();
                setType("pass");
              }}
              isMiddle
              buttonType="success"
            />
          </div>
        </div>

        <Table
          hasOrderColumn
          hasSearchButton={true}
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Email",
              key: "searchString",
              input: TableFilterInputText,
            },
            {
              name: translate("time"),
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: startOfMonth, //new Date(last1MothDate),
                toDate: endOfDay, //new Date(),
              },
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };

            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            setParams(params);

            return AdminService.getListPromotion({
              page: params.pageNumber,
              numberOfItemsPerPage: params.limit,
              ...params,
            }).then((res) => {
              setTotalAmount(res?.totalAmount);

              return res;
            });
          }}
        />
      </div>
    );
  })
);
