import React, { FC } from 'react'
// @ts-ignore
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { IInputProps, ObjectUtils, Icon } from '../../../modules'
import { InputSelect } from '../select';

interface IInputSelectSortable extends IInputProps {
    options: any,
}

export const InputSelectSortable: FC<IInputSelectSortable> = (props) => {
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        props.onChange(arrayMove(props.value, oldIndex, newIndex))
    }

    const DragHandle = sortableHandle(() => (
        <span className="DragHandle">
            <svg id="handleIcon" height="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 17"><path d="M6,7H8.5L5,3.5,1.5,7H4V17H1.5L5,20.5,8.5,17H6Zm4-2V7H22V5Zm0,14H22V17H10Zm0-6H22V11H10Z" transform="translate(-1.5 -3.5)" /></svg>
        </span>
    ));

    const SortableItem = sortableElement(({ value }: any) => {
        const label = ObjectUtils.getIn(props.options.find((v: any) => v.value === value), 'label', '--')
        return (
            <li className="SortableItem">
                <DragHandle />

                <div className="value">
                    {label}
                </div>

                <div className="btnRemove" onClick={() => props.onChange(props.value.filter((v: any) => v !== value))}>
                    <Icon.Remove />
                </div>
            </li>
        )
    });

    const SortableList = sortableContainer(({ items }: any) => {
        return (
            <ul className="SortableList">
                {items.map((value: any, index: number) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} />
                ))}
            </ul>
        );
    });

    return (
        <div className="InputSelectSortable">
            <InputSelect {...props}
                value=""
                onChange={e => props.onChange([...props.value, e])}
                options={props.options.filter((v: any) => !props.value.includes(v.value))}
            />

            <div className="sortAble">
                <SortableList
                    useDragHandle
                    items={props.value || []}
                    onSortEnd={onSortEnd}
                />
            </div>
        </div>
    )
}