import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { useSelector } from "../../store";

export interface IConfigWraperProps extends RouteComponentProps {}

export const withConfigWraper = (Component: FC<IConfigWraperProps>) => (props: IConfigWraperProps) => {
  const user = useSelector((state) => state.user);

  return (
    <div className="withConfigWraper">
      <div className="TabLinks">
        <NavLink to={Routes.withdrawConfig.path} exact={true}>
          <Icon.Setting />
          Withdraw Config
        </NavLink>

        <NavLink to={Routes.changePassword.path} exact={true}>
          <Icon.Setting />
          Change Password
        </NavLink>

        {user.userRole === "SUPER_ADMIN" && (
          <NavLink to={Routes.activities.path} exact={true}>
            <Icon.Setting />
            Activities
          </NavLink>
        )}

        {(user.userRole === "SUPER_ADMIN" || user.userRole === "ADMIN") && (
          <>
            <NavLink to={Routes.maintenanceConfig.path} exact={true}>
              <Icon.Setting />
              Maintenance Config
            </NavLink>
            <NavLink to={Routes.rolePermission.path} exact={true}>
              <Icon.Setting />
              Role Permission
            </NavLink>
            <NavLink to={Routes.News.path} exact={true}>
              <Icon.Setting />
              News
            </NavLink>
          </>
        )}
      </div>

      <Component {...props} />
    </div>
  );
};
