import Cookies from "universal-cookie";
const cookies = new Cookies();

const DefaultConfigs = {
  ENV: "local",

  // General information
  PUBLIC_URL: "http://localhost:3200",
  NUMBER_DECIMAL_DISPLAY: 8,
  DEFAULT_RECOVERY_USDT_EMAIL: "superadmin@vlat.prod",

  // RESTful APIs
  URL_API_MAIN_CLIENT_SIDE: "https://mbb-api-dm.2mau.tech/api",
  URL_SOCKET: "",
  URL_SOCKET_APP_ID: "",
  URL_API_MAIN_STORAGE_SIDE: "https://mbb-api-dm.2mau.tech/storage",

  GOOGLE_RECAPTCHA_KEY: "6LfCMKknAAAAAPNHDV7qbsZlJWl0XUpDomBMAiUH",
  TRADE_REAL_COIN_CODE: "USD",
  TRADE_DEMO_COIN_CODE: "DEMO",
  PASSWORD_SUPERADMIN: "",
  BSC_SCAN: "https://testnet.bscscan.com/tx/",
  TRON_SCAN: "https://nile.tronscan.org/#/transaction/",
  GT4_CAPTCHA_ID: "8465bfb60e8ca68cf04283922c3bff1b",
};

export function getEnv(
  key:
    | "ENV"

    // General information
    | "PUBLIC_URL"
    | "NUMBER_DECIMAL_DISPLAY"
    | "DEFAULT_RECOVERY_USDT_EMAIL"

    // RESTful APIs
    | "URL_API_MAIN_CLIENT_SIDE"
    | "URL_SOCKET"
    | "URL_SOCKET_APP_ID"
    | "URL_API_MAIN_STORAGE_SIDE"
    | "GOOGLE_RECAPTCHA_KEY"
    | "TRADE_REAL_COIN_CODE"
    | "TRADE_DEMO_COIN_CODE"
    | "PASSWORD_SUPERADMIN"
    | "BSC_SCAN"
    | "TRON_SCAN"
    | "GT4_CAPTCHA_ID"
): string {
  return cookies.get(key) || DefaultConfigs[key];
}

export const isDev = getEnv("ENV") !== "production";

export const CollectionDisplayOptions = [
  {
    label: "Trang chủ",
    value: "home",
  },
  {
    label: "Dành cho người mới",
    value: "new-member",
  },
  {
    label: "Trang sản phẩm",
    value: "product-page",
  },
];
