import React from "react";
import { withFiatWraper } from "..";
import { NumberUtils, Table } from "../../../modules";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import moment from "moment";
import { AdminService } from "../../../services/admin";
import { translate } from "../../../languages";
import { getEnv } from "../../../configs";
import _ from "lodash";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";

type Props = {};

export const DepositFiat = withFiatWraper(({}: Props) => {
  const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

  const StatusFilterOptions = [
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "FAILED", value: "FAILED" },
    { label: "PENDING", value: "PENDING" },
  ];

  return (
    <div className="FiatList">
      <Table
        hasOrderColumn
        itemPerPages={10}
        filters={[
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth, //new Date(last1MothDate),
              toDate: endOfDay, //new Date(),
            },
          },
          {
            name: "Email",
            key: "email",
            input: TableFilterInputText,
          },
          {
            name: "Status",
            key: "status",
            input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={StatusFilterOptions} isSearchable={false} />,
          },
        ]}
        structure={[
          {
            name: "EMAIL",
            key: "depositorEmail",
            className: "email",
          },
          {
            name: "ORDER ID",
            key: "orderId",
          },
          {
            name: "AMOUNT",
            key: "amount",
            render: (item) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
          },
          {
            name: "AMOUNT (USD)",
            key: "amountUsd",
            render: (item) => NumberUtils.toFormatNumber(+item?.amountUsd, +getEnv("NUMBER_DECIMAL_DISPLAY")),
          },
          {
            name: "CURRENCY",
            key: "currency",
          },
          {
            name: "DEPOSIT METHOD",
            key: "depositMethod",
            render: (item) => `${item?.depositMethod?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`,
          },
          {
            name: "FAILURE REASON",
            key: "failureReason",
            render: (item) =>
              item?.failureReason
                ? `${item?.failureReason?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`
                : "",
          },
          {
            name: "TIME",
            key: "created",
            render: (item) => {
              return <span>{moment(item?.createdAt).format("DD/MM/y HH:mm:ss")}</span>;
            },
          },
          {
            name: "STATUS",
            key: "status",
            render: (item: any) => (
              <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.status)))}</span>
            ),
          },
        ]}
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListFiat("deposits", {
            page: params.pageNumber,
            numberOfTransactionsPerPage: params.limit,
            ...params,
          });
        }}
      />
    </div>
  );
});
