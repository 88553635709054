import _ from "lodash";
import moment from "moment";
import React, { FC, useState } from "react";
import XLSX from "xlsx";
import { GeneralPopupCompnt } from "../../components/general-popup-compnt";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";
import { getEnv } from "../../configs";
import { getLocaleKey, translate } from "../../languages";
import { Button, CreateAlert, Icon, NumberUtils, Table } from "../../modules";
import { AdminService } from "../../services/admin";

export const PageClaimList: FC = (props: any) => {
  const StatusFilterOptions = [
    { label: "PENDING", value: "PENDING" },
    { label: "APPROVED", value: "APPROVED" },
    { label: "REJECT", value: "REJECT" },
  ];

  // const BotStatusFilterOptions = [
  //   { label: "PENDING", value: "PENDING" },
  //   { label: "SUCCESS", value: "SUCCESS" },
  //   { label: "FAILURE", value: "FAILURE" },
  // ];

  const [currentData, setCurrentData] = useState(null as any);
  const [valueCheckbox, setValueCheckbox] = useState<any>([]);
  const [isShowPopupApprove, setIsShowPopupApprove] = useState<boolean>(false);
  const [isShowPopupReject, setIsShowPopupReject] = useState<boolean>(false);
  const [messagePopup, setMessagePopup] = useState<any>();
  const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [indexExperienceGameId, setIndexExperienceGameId] = useState<any>();

  const startOfMonth = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

  const handleOnClickConfirmApprove = () => {
    let payload;
    if (valueCheckbox[0]?.value === -1 && valueCheckbox[0]?.checked === true) {
      payload = {
        requestClaimIds: [0],
        approved: true,
      };
    }
    if (valueCheckbox[0]?.value !== -1) {
      let tempArr = valueCheckbox?.filter((item: any) => item?.checked === true);
      let tempRequestClaimIds = tempArr.map((item: any) => item?.value?.requestClaimId);
      payload = {
        requestClaimIds: tempRequestClaimIds,
        approved: true,
      };
    }
    setIsShowTable(false);
    AdminService.handleRequestClaim(payload)
      .then((res) => {
        setValueCheckbox([]);
        setIsShowTable(true);
        setIsShowPopupApprove(false);
        return CreateAlert({
          message: "Approved successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        setValueCheckbox([]);
        setIsShowTable(true);
        setIsShowPopupApprove(false);
        return CreateAlert({ message: translate(err.message), type: "danger" });
      });
  };

  const handleOnClickConfirmReject = () => {
    let payload;
    if (valueCheckbox[0]?.value === -1 && valueCheckbox[0]?.checked === true) {
      payload = {
        requestClaimIds: [0],
        approved: false,
      };
    }
    if (valueCheckbox[0]?.value !== -1) {
      let tempArr = valueCheckbox?.filter((item: any) => item?.checked === true);
      let tempRequestClaimIds = tempArr.map((item: any) => item?.value?.requestClaimId);
      payload = {
        requestClaimIds: tempRequestClaimIds,
        approved: false,
      };
    }
    setIsShowTable(false);
    AdminService.handleRequestClaim(payload)
      .then((res) => {
        setValueCheckbox([]);
        setIsShowTable(true);
        setIsShowPopupReject(false);
        return CreateAlert({
          message: "Reject successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        setValueCheckbox([]);
        setIsShowTable(true);
        setIsShowPopupReject(false);
        return CreateAlert({ message: translate(err.message), type: "danger" });
      });
  };

  const handleOnClickApproveButton = () => {
    if (valueCheckbox[0]?.value === -1 && valueCheckbox[0]?.checked === true) {
      setMessagePopup("Are you sure to approve all?");
    }
    if (valueCheckbox[0]?.value !== -1) {
      let numberSelectedRows = valueCheckbox?.filter((item: any) => item?.checked === true).length;
      setMessagePopup(
        `Are you sure to approve ${numberSelectedRows} row${
          numberSelectedRows > 1 ? "s" : ""
        } selected?`
      );
    }
    setIsShowPopupApprove(true);
  };

  const handleOnClickRejectButton = () => {
    if (valueCheckbox[0]?.value === -1 && valueCheckbox[0]?.checked === true) {
      setMessagePopup("Are you sure to reject all?");
    }
    if (valueCheckbox[0]?.value !== -1) {
      let numberSelectedRows = valueCheckbox?.filter((item: any) => item?.checked === true).length;
      setMessagePopup(
        `Are you sure to reject ${numberSelectedRows} row${
          numberSelectedRows > 1 ? "s" : ""
        } selected?`
      );
    }
    setIsShowPopupReject(true);
  };

  const handleOnClickActionOrders = (item: any) => {
    setIndexExperienceGameId(item?.experienceGameId);
  };

  return (
    <div className="page-claim-list">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="success"
          className="mb15"
          onClick={async () => {
            // const response = await AdminService.getListRequestClaim({
            //     numberOfTransactionsPerPage: 10000,
            //     page: 1,
            //     fromDate: startOfMonth, //'0',
            //     toDate: endOfDay //moment().toISOString()
            // });
            const data = [
              [
                "Email",
                "Time Request",
                "Experience Package",
                "Refund Amount",
                "Proof Photo 1",
                "Proof Photo 2",
                "Status",
                "Bot Status",
              ],
              ...currentData.data.map((item: any) => {
                let row: any[] = [];
                row.push(item.email);
                //row.push(moment.utc(item?.reqDate).format('YYYY/MM/DD HH:mm'));
                row.push(moment(item?.created).format('DD/MM/y HH:mm:ss'));
                row.push(new Intl.NumberFormat("en").format(item?.packageValue));
                row.push(
                  NumberUtils.toFormatNumber(
                    item?.packageValue * 0.03,
                    +getEnv("NUMBER_DECIMAL_DISPLAY")
                  )
                );
                row.push(item?.proofImage1);
                row.push(item?.proofImage2);
                row.push(translate(`[ClaimScreen-StatusValue][${item?.status}]`));
                row.push(translate(`[ClaimScreen-BotStatusValue][${item?.botStatus}]`));

                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Claim-Table-${now.toLocaleDateString().replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="button-group">
          <Button
            disabled={valueCheckbox.length === 0}
            label="Approve"
            buttonType="success"
            className="approve-button"
            onClick={handleOnClickApproveButton}
          />
          <Button
            disabled={valueCheckbox.length === 0}
            label="Reject"
            buttonType="danger"
            className="reject-button"
            onClick={handleOnClickRejectButton}
          />
        </div>
      </div>

      {isShowTable && (
        <Table
          hasOrderColumn
          hasCheckbox={true}
          onChangeCheckbox={(data) =>
            setValueCheckbox((preState: any) => {
              if (data.value === -1) {
                //data.value === -1 => check all or uncheck all
                if (data?.checked === true) return [data];
                if (data?.checked === false) return [];
              }
              let temp = preState?.filter(
                (item: any) => _.isEqual(item?.value, data?.value) === false
              );
              if (data?.checked === true) return [...temp, data];
              if (data?.checked === false) return [...temp];
            })
          }
          valueCheckbox={valueCheckbox}
          className="overload"
          filters={[
            {
              name: "Email",
              key: "email",
              input: TableFilterInputText,
            },
            {
              name: translate("time"),
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                //fromDate: moment().subtract(1,"months"),
                //toDate: moment(),
                fromDate: startOfMonth,
                toDate: endOfDay,
              },
            },
            {
              name: "Status",
              key: "status",
              input: (e) => (
                <TableFilterInputSelect
                  isClearable={true}
                  {...e}
                  options={StatusFilterOptions}
                  isSearchable={false}
                />
              ),
              defaultValue: "PENDING",
            },
            // {
            //     name: "Bot Status",
            //     key: "botStatus",
            //     input: (e) => (
            //         <TableFilterInputSelect
            //             isClearable={false}
            //             {...e}
            //             options={BotStatusFilterOptions}
            //         />
            //     )
            // }
          ]}
          structure={[
            // {
            //     name: translate('Checkbox'),
            //     // key: "reqDate",
            //     render: (item:any) => <span><input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" /></span>
            // },
            {
              name: "EMAIL",
              key: "email",
              className: "email",
            },
            {
              name: "TIME REQUEST",
              key: "created",
              render: (item) => {
                const date = new Date(item.created);
                return (
                  <span>
                    {moment(item?.created).format('DD/MM/y HH:mm:ss')}
                  </span>
                );
              },
            },
            {
              name: "EXPERIENCE PACKAGE",
              render: (item: any) => new Intl.NumberFormat("en").format(item?.packageValue),
            },
            {
              name: "REFUND AMOUNT",
              render: (item: any) =>
                NumberUtils.toFormatNumber(item?.refundValue, +getEnv("NUMBER_DECIMAL_DISPLAY")),
            },
            {
              name: "PROOF PHOTO 1",
              key: "proofImage1",
              render: (item: any) => (
                <a href={item?.proofImage1} target="__blank">
                  <img className="proof-photo-1" src={item?.proofImage1} alt="" />
                </a>
              ),
            },
            {
              name: "PROOF PHOTO 2",
              key: "proofImage2",
              render: (item: any) => (
                <a href={item?.proofImage2} target="__blank">
                  <img className="proof-photo-2" src={item?.proofImage2} alt="" />
                </a>
              ),
            },
            {
              name: "STATUS",
              key: "status",
              render: (item: any) => (
                <span className={`status status--${item?.status?.toLowerCase()}`}>
                  {_.startCase(_.toLower(translate(item?.status)))}
                </span>
              ),
            },
            {
              name: "BOT STATUS",
              key: "botStatus",
              render: (item: any) => (
                <span className={`bot-status bot-status--${item?.botStatus?.toLowerCase()}`}>
                  {_.startCase(_.toLower(translate(item?.botStatus)))}
                </span>
              ),
            },
            {
              name: "ACTIONS",
              render: (item: any) => (
                <div
                  className="action action--orders"
                  title="See first 8 orders"
                  onClick={() => handleOnClickActionOrders(item)}
                >
                  <Icon.ActionOrders />
                </div>
              ),
            },
          ]}
          // fetchData={async (params) =>
          //     AdminService.getListRequestClaim({
          //         ...params,
          //         numberOfTransactionsPerPage: params.limit,
          //         page: params.pageNumber,
          //         fromDate: '0',
          //         toDate: moment().toISOString()
          //     }).then((res) => {
          //         setCurrentData(res);
          //         return res;
          //     })
          // }
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            return AdminService.getListRequestClaim({
              page: params.pageNumber,
              numberOfTransactionsPerPage: params.limit,
              ...params,
            }).then((res) => {
              setCurrentData(res);
              return res;
            });
          }}
        />
      )}
      {isShowPopupApprove ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupApprove(false);
          }}
          onClickConfirm={() => handleOnClickConfirmApprove()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={messagePopup}
        />
      ) : null}
      {isShowPopupReject ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupReject(false);
          }}
          onClickConfirm={() => handleOnClickConfirmReject()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={messagePopup}
        />
      ) : null}
      {indexExperienceGameId && (
        <OrdersPopupCompnt
          indexExperienceGameId={indexExperienceGameId}
          onClose={() => setIndexExperienceGameId(undefined)}
        />
      )}
    </div>
  );
};

const OrdersPopupCompnt: FC<any> = (props: any) => {
  return (
    <div className="orders-popup-compnt">
      <div className="orders">
        <div className="indicator-close" onClick={() => props?.onClose()}>
          <Icon.Close />
        </div>
        <div className="title">First 8 Orders</div>
        <Table
          structure={[
            {
              name: "DATE TIME",
              key: "created",
              render: (item: any) => moment(item?.created).format('DD/MM/y HH:mm:ss'),
            },
            {
              name: "AMOUNT",
              render: (item: any) =>
                NumberUtils.toFormatNumber(item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")) + "$",
            },
            {
              name: "RESULT",
              // render: (item: any) => <span className={`status status--${item?.status?.toLowerCase()}`}>{new Intl.NumberFormat("en").format(item?.profit)}</span>,
              render: (item: any) => (
                <span className={`status status--${item?.status?.toLowerCase()}`}>
                  {item.status === "LOSE"
                    ? item.profit > 0
                      ? NumberUtils.toFormatNumber(
                          item.amount - item.profit,
                          +getEnv("NUMBER_DECIMAL_DISPLAY")
                        )
                      : NumberUtils.toFormatNumber(item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : item.status === "WIN"
                    ? NumberUtils.toFormatNumber(
                        item.profit - item.amount,
                        +getEnv("NUMBER_DECIMAL_DISPLAY")
                      )
                    : NumberUtils.toFormatNumber(item.profit, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                  $
                </span>
              ),
            },
          ]}
          fetchData={async (state) =>
            AdminService.getOrdersForClaimRequest(props?.indexExperienceGameId)
          }
        />
      </div>
    </div>
  );
};
