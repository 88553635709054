import React from "react";
import { Button } from "../../../modules";
import { withConfigWraper } from "../wraper";
import AnnouncementCompnt from "./news-compnt";

type Props = {};

const AnnouncementPage = withConfigWraper((props: Props) => {
  return (
    <div className="row mb20">
      <div className="col-sm-12">
        <AnnouncementCompnt />
      </div>
    </div>
  );
});

export default AnnouncementPage;
