import moment from "moment";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Routes } from "../../../../AppRoutes";
import { InputDateTime, InputText } from "../../../../components";
import { InputImageSingle } from "../../../../components/single-image";
import { Tabs } from "../../../../components/tabs";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { ConfigService } from "../../../../services/config/config.service";
import { EditorForm } from "../editor";

export const CreateNewsPage: FC = (props) => {
  const { push } = useHistory();

  const [content, setContent] = useState<any>({
    "en-US": "",
    "vi-VN": "",
    "ko-KR": "",
    "zh-CN": "",
    "ru-RU": "",
  });

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "titleEN",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleVI",
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleKO",
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleZH",
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleRU",
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "image",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "expirationDate",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const { titleEN, titleVI, titleKO, titleZH, titleRU, image, expirationDate } = values;

      return ConfigService.createNews({
        title: {
          "en-US": titleEN,
          "vi-VN": titleVI,
          "ko-KR": titleKO,
          "zh-CN": titleZH,
          "ru-RU": titleRU,
        },
        content,
        image: image?.src,
        expirationDate: moment(expirationDate).toISOString(),
      })
        .then(() => {
          CreateAlert({
            message: "Create success.",
            type: "success",
          });
          push(Routes.News.path);
        })
        .catch((error: any) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });

  return (
    <>
      <h1
        className="mb40"
        style={{
          fontSize: "1.5rem",
          fontWeight: "lighter",
        }}
      >
        Create News
      </h1>

      <form className="Edit" onSubmit={handleSubmit}>
        <Tabs
          tabs={[
            {
              label: "English",
              id: "titleEN",
              subId: "en-US",
            },
            {
              label: "Vietnamese",
              id: "titleVI",
              subId: "vi-VN",
            },
            {
              label: "Korean",
              id: "titleKO",
              subId: "ko-KR",
            },
            {
              label: "Chinese",
              id: "titleZH",
              subId: "zh-CN",
            },
            {
              label: "Russian",
              id: "titleRU",
              subId: "ru-RU",
            },
          ]}
          render={(tab) => {
            return (
              <>
                <InputWraper label={"Title"} className="mt20" inputProps={getInputProps(tab.id)} component={InputText} />
                <EditorForm
                  title="Content"
                  dataDefault={content[`${tab.subId}`]}
                  getData={(data: any) => {
                    setContent((state: any) => {
                      state[`${tab.subId}`] = data;
                      return state;
                    });
                  }}
                />
              </>
            );
          }}
        ></Tabs>

        <InputWraper label={"Image"} inputProps={getInputProps("image")} component={InputImageSingle} className="mr5" />
        <InputWraper label={translate("Expiration Date")} inputProps={getInputProps("expirationDate")} component={InputDateTime} className="ml5" />
        <div>
          <Button
            label="Create"
            isMiddle
            buttonType="success"
            className="btnBan"
            type="submit"
            disabled={content["en-US"] === ""}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </>
  );
};
