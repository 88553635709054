import React, { useState } from "react";
import { withFiatWraper } from "..";
import { Button, CreateAlert, InputWraper, NumberUtils, Table, onError, useForm } from "../../../modules";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import moment from "moment";
import { AdminService } from "../../../services/admin";
import { translate } from "../../../languages";
import { getEnv } from "../../../configs";
import _ from "lodash";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { InputText, InputTextArea } from "../../../components";

type Props = {};

export const WithdrawFiat = withFiatWraper(({}: Props) => {
  const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [actionType, setActionType] = useState<any>();
  const [item, setItem] = useState<any>();
  const [fetchData, setFetchData] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  
  const StatusFilterOptions = [
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "FAILED", value: "FAILED" },
    { label: "PENDING", value: "PENDING" },
  ];

  enum EActionType {
      APPROVE = "approve",
      REJECT = "reject"
  }

  let handleOnClickApproveButton = (item: any, fetchData:any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.APPROVE);
      setIsShowPopup(true);
  }

  let handleOnClickRejectButton = (item: any, fetchData:any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.REJECT);
      setIsShowPopup(true);
  }

  let handleOnClickConfirm = (actionType:any, item:any, fetchData:any) => {
      setIsLoading(true);
      if (actionType === EActionType.APPROVE) {
          AdminService.FiatWithdrawApprove(item?.transactionRequestId)
          .then((res) => {
              fetchData();
              CreateAlert({message: res?.status, type: 'success'})
          })
          .catch(onError)
          .finally(() => {
              setIsLoading(false);
              setIsShowPopup(false);
          })
      }

      if (actionType === EActionType.REJECT) {
          AdminService.FiatWithdrawReject(item?.transactionRequestId, {rejectReason: getInputProps("reason").value})
          .then((res) => {
              fetchData();
              CreateAlert({message: res?.status, type: 'success'})
          })
          .catch(onError)
          .finally(() => {
              setIsLoading(false);
              setIsShowPopup(false);
              getInputProps("reason").onChange(null);
          })
      }
  }

  const { getInputProps, handleSubmit, isSubmitting } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "reason",
      }
    ],
    onSubmit: async (values) => {
    },
  });

  return (
    <div className="FiatList">
      <Table
        hasOrderColumn
        itemPerPages={10}
        filters={[
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth, //new Date(last1MothDate),
              toDate: endOfDay, //new Date(),
            },
          },
          {
            name: "Email",
            key: "email",
            input: TableFilterInputText,
          },
          {
            name: "Status",
            key: "status",
            input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={StatusFilterOptions} isSearchable={false} />,
          },
        ]}
        structure={[
          {
            name: "EMAIL",
            key: "username",
            className: "email",
          },
          {
            name: "ORDER ID",
            key: "orderId",
          },
          {
            name: "AMOUNT",
            key: "amount",
            render: (item) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
          },
          {
            name: "AMOUNT (USD)",
            key: "amountUsd",
            render: (item) => NumberUtils.toFormatNumber(+item?.amountUsd, +getEnv("NUMBER_DECIMAL_DISPLAY")),
          },
          {
            name: "CURRENCY",
            key: "currency",
          },
          {
            name: "WITHDRAW METHOD",
            key: "payoutMethod",
            render: (item) => `${item?.payoutMethod?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`,
          },
          {
            name: "FAILURE REASON",
            key: "failureReason",
            render: (item) =>
              item?.failureReason
                ? `${item?.failureReason?.split("_")?.reduce((res: string, item: any, idx: number) => (res = `${res} ${item}`), "")}`
                : "",
          },
          {
            name: "TIME",
            key: "created",
            render: (item) => {
              return <span>{moment(item?.createdAt).format("DD/MM/y HH:mm:ss")}</span>;
            },
          },
          {
            name: "STATUS",
            key: "status",
            render: (item: any) => (
              <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.status)))}</span>
            ),
          },
          {
            name: 'ACTIONS',
            render: (item, fetchData) => {
                return <div className="fiat-action">
                    <Button
                        className="mr5"
                        type="button"
                        buttonType="success-outline"
                        label="Approve"
                        onClick={() => handleOnClickApproveButton(item, () => fetchData)}
                        disabled={item?.status!=='JUST_CREATED'}
                    />

                    <Button
                        type="button"
                        buttonType="danger-outline"
                        label="Reject"
                        onClick={() => handleOnClickRejectButton(item, () => fetchData)}
                        disabled={item?.status!=='JUST_CREATED'}
                    />
                </div>
            }
          },
        ]}
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListFiat("payouts", {
            page: params.pageNumber,
            numberOfTransactionsPerPage: params.limit,
            ...params,
          });
        }}
      />

    {isShowPopup && (
        <GeneralPopupCompnt
          onClose={() => {
              setIsShowPopup(false);
          }}
          onClickConfirm={() => handleOnClickConfirm(actionType, item, fetchData)}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={[`Are you sure to `,[<span key={'actionType'} style={{color: actionType===EActionType.APPROVE ? '#56ca00' : '#ff4c51'}}>{actionType}</span>],` the withdrawal of `,[<span key={'amountUsd'} style={{color: '#4680FF'}}>{item?.amountUsd} USDT</span>],` by `,[<span key={'username'} style={{color: '#4680FF'}}>{item?.username}</span>],`?`]}
          isLoading={isLoading}
        >
          {(actionType === EActionType.REJECT) && <InputWraper
            label={"Reason"}
            inputProps={getInputProps("reason")}
            component={InputTextArea}
          />}
        </GeneralPopupCompnt>
    )}
    </div>
  );
});
